import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const citiesApi = factory.get("cities");
const initialState = {
  cities: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
    },
    dialog: false,
  },
  manageCities: {
    form: {
      id: "",
      name_en: "",
      name_ar: "",
      province_id: null,
    },
    dialog: false,
    loading: false,
  },
};

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setCities: (state, action) => {
      state.cities.data = action.payload.data;
      state.cities.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.cities.filters = {
        ...state.cities.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.cities.filters = initialState.cities.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageCities.form.id = payload.id;
        state.manageCities.form.name_en = payload.name_en;
        state.manageCities.form.name_ar = payload.name_ar;
        state.manageCities.form.province_id = payload.province_id;
      } else {
        state.manageCities.form = initialState.manageCities.form;
      }
      state.manageCities.dialog = !state.manageCities.dialog;
    },
    resetForm: (state, action) => {
      state.manageCities.form = initialState.manageCities.form;
    },
  },
});

export const {
  setLoading,
  setCities,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
} = citiesSlice.actions;

export default citiesSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().cities.cities.filters;
    dispatch(setLoading("cities"));
    const res = await citiesApi.index(filters);
    dispatch(setCities(res.data));
    dispatch(setLoading("cities"));
  } catch (err) {
    dispatch(setLoading("cities"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    const infoData = {
      name_en: data.name_en,
      name_ar: data.name_ar,
      province_id: data.province_id.id,
    };
    dispatch(setLoading("manageCities"));
    await citiesApi.create(infoData);
    dispatch(setLoading("manageCities"));
    dispatch(
      showNotification({
        type: "success",
        message: "City Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageCities"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    const infoData = {
      name_en: data.name_en,
      name_ar: data.name_ar,
      province_id: data.province_id.id,
    };
    dispatch(setLoading("manageCities"));
    await citiesApi.update(id, infoData);
    dispatch(setLoading("manageCities"));
    dispatch(
      showNotification({
        type: "success",
        message: "City updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageCities"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteCity = (id) => async (dispatch, getState) => {
  try {
    await citiesApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "City deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const Cities = {
  index,
  create,
  update,
  deleteCity,
};
