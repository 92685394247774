import { Box, Button, Typography, useTheme, ButtonGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "./Chart/LineChart";
import StatBox from "./Chart/StatBox";
import { useEffect } from "react";
import { useState } from "react";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WorkIcon from "@mui/icons-material/Work";
import LoadingCircle from "../Others/LoadingCircle";
import { factory } from "../../api/apiFactory";
import PieChart from "./Chart/PieChart";

const statisticsApi = factory.get("statistics");

const generateDistinctColor = (index, total) => {
  const goldenRatio = 0.618033988749895;
  const hue = (index / total + goldenRatio) % 1;

  return `hsl(${Math.floor(hue * 360)}, 70%, ${45 + Math.random() * 10}%)`;
};

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  const [dataLine, setDataLine] = useState([]);
  const [jobDataLine, setJobDataLine] = useState([]);
  const [duration, setDuration] = useState("weekly");
  const [jobDuration, setJobDuration] = useState("weekly");
  const [provinceStats, setProvinceStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pieChartData, setPieChartData] = useState([]);
  useEffect(() => {
    // get data cities
    statisticsApi.index().then((res) => {
      setData(res.data.data[0]);
    });
  }, []);
  useEffect(() => {
    statisticsApi.indexProvinceStatistics().then((res) => {
      const dataEmployers = Object.entries(res.data.data).map(
        ([province, data]) => ({
          x: province,
          y: data.totalEmployers,
        })
      );

      const dataSeekers = Object.entries(res.data.data).map(
        ([province, data]) => ({
          x: province,
          y: data.totalSeekers,
        })
      );

      const dataJobs = Object.entries(res.data.data).map(
        ([province, data]) => ({
          x: province,
          y: data.totalJobs,
        })
      );
      const durationLineData = [
        {
          id: "Employers",
          color: "#a62639",
          data: dataEmployers ? dataEmployers : [],
        },
        {
          id: "Seekers",
          color: tokens("dark").blueAccent[300],
          data: dataSeekers ? dataSeekers : [],
        },
        {
          id: "Jobs",
          color: tokens("dark").redAccent[300],
          data: dataJobs ? dataJobs : [],
        },
      ];
      setProvinceStats(durationLineData);

      const pieChartData = Object.entries(res.data.data)
        .filter(([_, data]) => data.totalSeekers > 0)
        .map(([province, data], index, array) => ({
          id: province,
          label: province,
          value: data.totalSeekers,
          color: generateDistinctColor(index, array.length),
        }));
      setPieChartData(pieChartData);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    statisticsApi.indexDurationStatistics(duration).then((res) => {
      const dataEmployers = Object.entries(res.data.data).map(
        ([month, data]) => ({
          x:
            duration === "yearly"
              ? month?.split("/")[0]
              : duration === "monthly"
              ? month?.split("-")[2]
              : duration === "weekly"
              ? month?.split("-")[2]
              : "",
          y: data.total_employers,
        })
      );

      const dataSeekers = Object.entries(res.data.data).map(
        ([month, data]) => ({
          x:
            duration === "yearly"
              ? month?.split("/")[0]
              : duration === "monthly"
              ? month?.split("-")[2]
              : duration === "weekly"
              ? month?.split("-")[2]
              : "",
          y: data.total_seekers,
        })
      );

      const durationLineData = [
        {
          id: "Employers",
          color: "#a62639",
          data: dataEmployers ? dataEmployers : [],
        },
        {
          id: "Seekers",
          color: tokens("dark").blueAccent[300],
          data: dataSeekers ? dataSeekers : [],
        },
      ];
      setDataLine(durationLineData);
    });
  }, [duration]);
  useEffect(() => {
    statisticsApi.indexDurationStatistics(jobDuration).then((res) => {
      const dataJobs = Object.entries(res.data.data).map(([month, data]) => ({
        x:
          jobDuration === "yearly"
            ? month?.split("/")[0]
            : jobDuration === "monthly"
            ? month?.split("-")[2]
            : jobDuration === "weekly"
            ? month?.split("-")[2]
            : "",
        y: data.total_jobs,
      }));
      const durationLineData = [
        {
          id: "Jobs",
          color: tokens("dark").redAccent[300],
          data: dataJobs ? dataJobs : [],
        },
      ];
      setJobDataLine(durationLineData);
    });
  }, [jobDuration]);
  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
        <Box
          width="100%"
          backgroundColor="#ffffff"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <StatBox
            title={data?.total_employers}
            subtitle="Total Employers"
            progress=".5"
            increase=""
            icon={
              <SupervisedUserCircleIcon
                sx={{ fontSize: "26px" }}
                color="primary"
              />
            }
          />
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
        <Box
          width="100%"
          backgroundColor="#ffffff"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <StatBox
            title={data?.total_seekers}
            subtitle="Total Seekers"
            progress=".5"
            increase=""
            icon={
              <AssignmentIndIcon sx={{ fontSize: "26px" }} color="primary" />
            }
          />
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
        <Box
          width="100%"
          backgroundColor="#ffffff"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <StatBox
            title={data?.total_jobs}
            subtitle="Total Jobs"
            progress=".5"
            increase=""
            icon={<WorkIcon sx={{ fontSize: "26px" }} color="primary" />}
          />
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
        <Box
          width="100%"
          backgroundColor="#ffffff"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <StatBox
            title={data?.jobs_this_month}
            subtitle="Jobs this Month"
            progress=".5"
            increase=""
            icon={<TrafficIcon sx={{ fontSize: "26px" }} color="primary" />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          mt={6}
          backgroundColor="#ffffff"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <Box
            my="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                User Statistics
              </Typography>
            </Box>
            <Box>
              <ButtonGroup size="small" aria-label="outlined button group">
                <Button
                  onClick={() => setDuration("weekly")}
                  variant={duration === "weekly" ? "contained" : "outlined"}
                >
                  Weekly
                </Button>
                <Button
                  onClick={() => setDuration("monthly")}
                  variant={duration === "monthly" ? "contained" : "outlined"}
                >
                  Monthly
                </Button>
                <Button
                  onClick={() => setDuration("yearly")}
                  variant={duration === "yearly" ? "contained" : "outlined"}
                >
                  Yearly
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box height="258px" m="-20px 0 0 0">
            <LineChart lineData={dataLine} isDashboard={true} />
          </Box>
        </Box>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Box
          mt={6}
          backgroundColor="#ffffff"
          alignItems="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          p="33px"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography variant="h5" color={"primary"} sx={{ mt: "15px" }}>
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <Grid container>


        </Grid>
      </Grid> */}
      {/* <Grid xs={12} sm={12} md={12}>
          <Box
            mt={6}
            backgroundColor="#ffffff"
            alignItems="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
            }}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              Sales Quantity
            </Typography>
            <Box height="250px" mt="-20px">
              <BarChart isDashboard={true} />
            </Box>
          </Box>
        </Grid> */}
      {/* <Grid item xs={12}>
          <Box
            mt={6}
            backgroundColor="#ffffff"
            alignItems="center"
            justifyContent="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
              width: "100%",
            }}
          >
            <Box
              my="25px"
              p="0 30px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Porvince Statistics
                </Typography>
              </Box>
            </Box>

            {!loading ? (
              <Box height="258px" m="-20px 0 0 0">
                <LineChart lineData={provinceStats} isDashboard={true} />
              </Box>
            ) : (
              <Box height="258px" m="-20px 0 0 0" pt="111.725px">
                <LoadingCircle />
              </Box>
            )}
          </Box>
        </Grid> */}
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Box
          mt={6}
          backgroundColor="#ffffff"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <Box
            my="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Seekers Province Statistics
              </Typography>
            </Box>
          </Box>

          {!loading ? (
            <Box height="258px" m="-20px 0 0 0">
              <PieChart pieData={pieChartData} isDashboard={true} />
            </Box>
          ) : (
            <Box height="258px" m="-20px 0 0 0" pt="111.725px">
              <LoadingCircle />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          mt={6}
          backgroundColor="#ffffff"
          alignItems="center"
          justifyContent="center"
          borderRadius={3}
          border="1px solid #d4d4d4"
          boxShadow="1px 4px 10px rgba(0,0,0,.1)"
          sx={{
            transition: ".3s all ease-in-out",
            ":hover": {
              transform: "scale(1.025)",
            },
          }}
        >
          <Box
            my="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Job Statistics
              </Typography>
            </Box>
            <Box>
              <ButtonGroup size="small" aria-label="outlined button group">
                <Button
                  onClick={() => setJobDuration("weekly")}
                  variant={jobDuration === "weekly" ? "contained" : "outlined"}
                >
                  Weekly
                </Button>
                <Button
                  onClick={() => setJobDuration("monthly")}
                  variant={jobDuration === "monthly" ? "contained" : "outlined"}
                >
                  Monthly
                </Button>
                <Button
                  onClick={() => setJobDuration("yearly")}
                  variant={jobDuration === "yearly" ? "contained" : "outlined"}
                >
                  Yearly
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box height="258px" m="-20px 0 0 0">
            <LineChart lineData={jobDataLine} isDashboard={true} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
