import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { useDispatch, useSelector } from "react-redux";
import {
  Feedback,
  resetFilters,
  setFilters,
} from "../../app/slices/feedbackSlice";
import { useDebounce } from "use-debounce";
export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    feedback: { data, filters, loading, total },
  } = useSelector((state) => state.feedback);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "employer-grid--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.name}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.email}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      minWidth: 60,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={`${params?.row?.rating} / 5`}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      minWidth: 400,
      headerClassName: "employer-grid--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params?.row?.message}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Feedback.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Feedback"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(Feedback.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Tabel;
